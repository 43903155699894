export const URL = "https://api.kittycryptogang.com";
// export const URL = "http://localhost:8080";
// export const URL = "https://powerful-harbor-54672.herokuapp.com";

export const PRICE = 0.25;
export const WL_SALE_TIME = 'Jan 26, 2022 14:00:00 UTC';
export const RAFFLE_SALE_TIME = 'Jan 26, 2022 20:00:00 UTC';
export const STATUS = {
    ERROR: 'error',
    SUCCESS: 'success',
};

/* ERC-721 CONTRACT */
// export const CONTRACT = "0xf4616A3e97CE23679D33b2736cd26fE8C54B1A94"; // Ropsten
export const CONTRACT = "0xA302F0d51A365B18e86c291056dC265a73F19419"; // Mainnet

/* STAKING CONTRACT */
// export const STAKING_CONTRACT = "0xF573a4669D56565e83Da8973B82D603B97Fc7Fe4" // Ropsten
export const STAKING_CONTRACT = "0x90BC95fe1B65Df5DB9ecb12f2dc0A555b05aD7F2" // Mainnet

export const AUTH0 = {
    DOMAIN: "dev--7f14qnk.us.auth0.com",
    CLIENT_ID: "XnpkvL5rcgfQHPXbBczj3bIYQ7qk1go0",
    SCOPES: "create:box read:box update:box",
    AUDIENCE: "https://kittycryptogang/api"
}