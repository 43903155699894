import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH0 } from './constants';


ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={AUTH0.DOMAIN}
            clientId={AUTH0.CLIENT_ID}
            redirectUri={`${window.location.origin}/admin`}
            audience={AUTH0.AUDIENCE}
            scope={AUTH0.SCOPES}
        >
            <App />
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
