import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Maintenance } from "./Routes/Maintenance";

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Maintenance />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
