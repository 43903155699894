import { Wrapper } from "../Components/Wrapper";


export function Maintenance() {
    return (
        <Wrapper>
            <div className="login">
                <h1 className="mt0">Go to the new Kitty Hub:</h1>
                <button onClick={() => window.open("https://hub.kittycryptogang.com/", "_self")}>Open Kitty Hub</button>
            </div>
        </Wrapper>
    )
}
