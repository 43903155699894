export function Wrapper({ children }) {
    return (
        <>
            <img src="/images/kitty.jpg" className="background-image" alt="Kitty" />
            <div className="shim" />
            <div className="container centered fadeIn">
                <img src="/images/logo.png" alt="Logo" height={115} width={115} />
                <h3 className="sub-heading">Welcome to the </h3>
                <h1 className="heading">KITTY CRYPTO GANG</h1>
                {children}
            </div>
        </>
    )
}